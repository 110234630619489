import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { shuffle } from 'lodash';

import Layout from '../components/layout';
import Head from '../components/head';

import styles from './listpage.module.scss';

const HomePage = ({
  data: {
    allVehicles: { nodes: allVehicles },
    featuredVehicles: { nodes: featuredVehicles },
  },
  pageContext: { ordering },
}) => {
  const [orderedVehicles, setOrderedVehicles] = useState([]);

  useEffect(() => {
    setOrderedVehicles(shuffle(featuredVehicles).slice(0, 20));
  }, [featuredVehicles, ordering]);

  return (
    <Layout>
      <Head title="Hollingdean Parking Home" />

      <main className={styles.listpage}>
        <div className={styles.content}>
          <p>
            This website has been created by a group of Hollingdean residents
            fed up with the large number of displaced vehicles in our area, and
            serves to demonstrate the scale of the problems residents face as a
            result of uncontrolled parking on our streets.
          </p>
          <p>
            Whilst the latest vote showed a majority against a parking scheme in
            Hollingdean, there is a significant division in the way roads in the
            South (with the highest amount of displacement) voted, while roads
            in the North of the ward face less strain and voted more strongly
            against.
          </p>
          <p>
            We also feel the recent consultation process, whilst it may have
            ticked the right boxes, didn't engage residents nearly enough. This
            has lead to confusion on the process, as well as a scheme design
            that didn’t take into account resident’s wishes.
          </p>
          <p>
            We want to petition Brighton & Hove council to revisit a resident's
            parking scheme in the South of Hollingdean where displacement is
            greatest and where support is strongest. This is an area where
            residents face both dangerous and illegal parking on a daily basis,
            increased traffic, air pollution, noise, and an inability to park in
            their own road, let alone near their home.
          </p>

          <br />
          <br />

          <h2>Featured vehicles</h2>
          <p>
            Below is just a small collection of some of the problems we face in
            our community.
          </p>
          <p>
            We have over <strong>{allVehicles.length}</strong> more examples of{' '}
            <Link to="/gallery/non-residential">non-resident</Link>,{' '}
            <Link to="/gallery/displaced/">displaced</Link>,{' '}
            <Link to="/gallery/commuters/">commuter</Link>,{' '}
            <Link to="/gallery/abandoned/">abandoned</Link>, tourist, (or a
            combination) <Link to="/gallery/cars/">cars</Link>,{' '}
            <Link to="/gallery/taxis/">taxis</Link>,{' '}
            <Link to="/gallery/vans/">vans</Link>, and{' '}
            <Link to="/gallery/camper-vans/">campers</Link>{' '}
            <Link to="/gallery">in our full gallery</Link>.
          </p>
        </div>

        <ul className={styles.grid}>
          {orderedVehicles.map(
            (
              {
                make,
                model,
                colour,
                numberPlate,
                gridImage: {
                  localFile: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
              i
            ) => (
              <li key={`featuredVehicles-${i}`} className={styles.item}>
                <Link
                  to={`/gallery/${numberPlate
                    .replace(' ', '-')
                    .toLowerCase()}/`}
                  className={styles.link}
                  aria-label={`View ${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                >
                  <GatsbyImage
                    image={gatsbyImageData}
                    alt={`${numberPlate}, a ${colour} ${make} ${model} parked in Hollingdean`}
                    placholder="dominantColor"
                    loading="lazy"
                  />
                  <h2 className={styles.numberplate}>{numberPlate}</h2>
                </Link>
              </li>
            )
          )}
        </ul>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query homePage {
    allVehicles: allContentfulVehicle {
      nodes {
        id
      }
    }
    featuredVehicles: allContentfulVehicle(filter: { featured: { eq: true } }) {
      nodes {
        id
        numberPlate
        colour
        make
        model
        gridImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 400
                quality: 40
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
